import {action, observable} from "mobx";

const str_format = (str, params = null) => {
    if (!params) return str;
    let newStr = str;
    for (const key of Object.keys(params)) {
        newStr = newStr.replace(`{${key}}`, params[key]);
    }
    return newStr;
};

class Language {
    constructor() {
        fetch(`${window.location.origin}/source/dict.json`)
          .then(res => res.json())
          .then(res => this.patchDict(res))
    }

    @observable lang = 'en';
    @action changeLang = (newLang) => {
        this.lang = newLang;
    };

    @observable.shallow dict = {};
    @action patchDict = newDict => this.dict = newDict;

    t = (key, params = null) => {
        if (!this.dict.hasOwnProperty(key)) return key;
        if (!this.dict[key].hasOwnProperty(this.lang)) return key;

        return str_format(this.dict[key][this.lang], params);
    }

    p = str => {
        if (this.lang !== 'en') return str;
        let newStr = str;

        const isAlpha = chr => {
            if (chr >= 'a' && chr <= 'z') return true;
            if (chr >= 'A' && chr <= 'Z') return true;
            return false;
        };

        const isCons = chr => {
            return 'bcdfghjklmnpqrstvwxz'.includes(chr);
        }

        for (let i = str.length - 2; i > 1; i--) {
            if (!isCons(str[i])) continue;

            let canInsert = true;
            for (let j = -3; j < 3; j++)
                if (!isAlpha(str[i + j])) {
                    canInsert = false;
                    break;
                }
            if (canInsert) newStr = newStr.substr(0, i) + "&shy;" + newStr.substr(i);
        }

        return newStr;
    }
}

export default Language;
