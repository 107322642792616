import {action, computed, observable} from "mobx";
import {createMuiTheme, darken, fade, lighten} from "@material-ui/core";
import Home from "../components/Home/Home";
import Members from "../components/Members/Members";
import Publications from "../components/Publications/Publications";
import MobHome from "../components/Home/MobHome";
import MobMembers from "../components/Members/MobMembers";
import MobPublications from "../components/Publications/MobPublications";
import {joinCookie, parseCookie} from './Cookie';

class SystemStore {
    constructor() {
        this.initTheme();
        this.initResolution();
    }

    optionalColors = ['#FC766A', '#349F9E', '#3FA9F5'];
    themeOptions = this.optionalColors.map(secondaryColor => ({
        palette: {
            primary: {
                main: '#333333'
            },
            secondary: {
                main: secondaryColor,
                contrastText: '#ffffff',
            },
            background: {
                default: '#ffffff',
                paper: '#ffffff',
            }
        },
        spacing: 10,
        typography: {
            fontFamily: 'Montserrat',
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: 10,
                    },
                    '*::-webkit-scrollbar-track': {
                        display: 'none',
                    },
                    '*::-webkit-scrollbar-corner': {
                        display: 'none',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: fade(secondaryColor, 0.1),
                        borderRadius: 10,
                    },
                    '*:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: lighten(secondaryColor, 0.2),
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: secondaryColor,
                    },
                    '*::-webkit-scrollbar-thumb:active': {
                        backgroundColor: darken(secondaryColor, 0.2),
                    }
                }
            }
        }
    }));
    @observable themeChoice = 1;
    @action changeTheme = (newTheme, save=true) => {
        this.themeChoice = newTheme;
        if (save) this.saveTheme();
    }
    @computed get theme() {
        return createMuiTheme(this.themeOptions[this.themeChoice]);
    };
    initTheme = () => {
        const cookie = parseCookie(document.cookie);
        if (!!(cookie.theme)) {
            this.changeTheme(cookie.theme, false);
        } else {
            this.changeTheme(parseInt((Math.random() * 3).toString(), 10), false);
        }
    }
    saveTheme = () => {
        document.cookie = joinCookie({
            ...parseCookie(document.cookie),
            theme: this.themeChoice,
        });
    }

    routes = [
        {
            path: '/',
            label: 'Home',
            page: Home,
            mobPage: MobHome,
            isExact: true,
        },
        // {
        //     path: '/members',
        //     label: 'Members',
        //     page: Members,
        //     mobPage: MobMembers,
        //     isExact: true,
        // },
        {
            path: '/publications',
            label: 'Publications',
            page: Publications,
            mobPage: MobPublications,
            isExact: false,
        },
    ];

    @observable width = 1920;
    @observable isMobile = false;
    @action changeResolution = (w, h) => {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
            this.isMobile = true;
        else if (w < 1100)
            this.isMobile = true;
        else this.isMobile = false;
        this.width = w;
    }
    initResolution = () => {
        this.changeResolution(window.innerWidth, window.innerHeight);
        window.onresize = () => this.changeResolution(window.innerWidth, window.innerHeight);
    };

    handleStatic = staticUrl => {
        let url = window.location.origin;
        if (url.endsWith('/')) url = url.substr(0,url.length - 1);
        url += staticUrl.startsWith('./') ? staticUrl.substr(1) : staticUrl;
        return url;
    }
}

export default SystemStore;
