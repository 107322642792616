import React from 'react';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    imageSrc: {
        '-webkit-mask-size': 'contain',
        '-webkit-mask-repeat': 'no-repeat',
        '-webkit-mask-position': 'center 100%',
    },
}));

function ColoredSvg({src, color, width, height, classes: overrideClasses}) {
    const classes = useStyles();

    return <div className={clsx(classes.imageSrc, overrideClasses)}
                style={{
                    'WebkitMaskImage': `url(${src})`,
                    backgroundColor: !!color ? color : undefined,
                    width: width ? width : 'auto',
                    height: height ? height : 'auto',
                }}/>
}

export default inject()(observer(ColoredSvg));
