import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, Paper, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import useLazyLoader from "../../../utils/hooks/lazyLoader";
import demoIcon from './demo.svg';
import paperIcon from './pdf.svg';
import videoIcon from './video.svg';

// const height = 310;
const topHeight = 30;
const iconSize = topHeight * 0.7;
const useStyles = makeStyles(theme => {
    const radius = theme.spacing(1.5);
    const translate = radius * (2 - Math.sqrt(2)) / 2;
    const triSize = topHeight - translate;
    return {
        root: {
            width: `calc(100% - ${theme.spacing}px)`,
            margin: theme.spacing(0.5, 0.5),
            "&$primary": {
                backgroundColor: theme.palette.primary.main,
                cursor: 'pointer'
            },
            "&$primary:hover": {
                backgroundColor: theme.palette.secondary.light,
            },
            "&$secondary": {
                backgroundColor: theme.palette.secondary.main,
            }
        },
        topBar: {
            width: '100%',
            height: topHeight,
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
        },
        source: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 100,
            padding: theme.spacing(0, 1),
            height: topHeight,
            borderTopLeftRadius: theme.spacing(0.5),
            borderBottomRightRadius: radius,
            "$primary &": {
                backgroundColor: theme.palette.primary.main,
            },
            "$primary:hover &": {
                backgroundColor: theme.palette.secondary.light,
            },
            "$secondary &": {
                backgroundColor: theme.palette.secondary.main,
            }
        },
        primary: {},
        secondary: {},
        sourceText: {
            width: '100%',
            textAlign: 'center',
            color: theme.palette.secondary.contrastText,
            lineHeight: `${topHeight}px`,
            fontSize: 21,
        },
        triangle: {
            position: 'absolute',
            right: -triSize,
            top: 0,
            width: 0,
            height: 0,
            transform: `translateX(-${translate}px)`,
            "$primary &": {
                borderTop: `${triSize}px solid ${theme.palette.primary.main}`,
            },
            "$primary:hover &": {
                borderTop: `${triSize}px solid ${theme.palette.secondary.light}`,
            },
            "$secondary &": {
                borderTop: `${triSize}px solid ${theme.palette.secondary.main}`,
            },
            borderRight: `${triSize}px solid transparent`,
        },
        title: {
            flex: 1,
            fontSize: 21,
            lineHeight: `${topHeight}px`,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        btns: {
            width: topHeight,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
        },
        btn: {
            margin: theme.spacing(0, 0, 1, 0),
            width: iconSize,
            height: iconSize,
            cursor: "pointer",
            border: 'none',
        },
        content: {
            width: '100%',
            padding: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(0.5),
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            position: 'relative',
        },
        imgPlaceholder: {
            width: '25%',
            flex: '0 0 auto',
            overflow: 'hidden',
        },
        imgContainer: {
            position: 'absolute',
            top: theme.spacing(2),
            bottom: theme.spacing(2),
            width: `calc(25% - ${theme.spacing(1)}px)`,
            flex: '0 0 auto',
            overflow: 'hidden',

            display: 'flex',
            alignItems: 'center',
        },
        img: {
            width: '100%',
        },
        textInfo: {
            flex: 1,
            marginLeft: topHeight,
        },
        textTitle: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: theme.spacing(1),
        },
        textTitleKey: {
            color: "red",
        },
        textAuthors: {
            fontSize: 18,
            marginBottom: theme.spacing(0.5),
        },
        textPublication: {
            fontStyle: 'italic',
            fontSize: 16,
        }
    };
});

function Project({visibleInfo,project, data, color = 'secondary', onClick = null, onLoad = null}) {
    const root = useRef(null);
    const classes = useStyles();
    const {id, source, title, authors, teaser, transaction, volume, issue, articleNo, page, year, demo, paper, video, titleKey} = project;
    const src = useLazyLoader(teaser, root);
    const [isVisible, setIsVisible] = useState(true); // 初始时图片不可见
    // const cRef = useRef(null)
    const height = visibleInfo?.height
    const top = visibleInfo?.top
    useEffect(() => {
        if (root.current && height && top){
            const offsetHeight = root.current.offsetHeight
            const scrollTop = root.current.offsetTop
            // for (const key in root.current)
            //     if (key.toLowerCase().includes('top') || key.includes('Y'))
            //         console.log(key, root.current[key]);
            const isElementInView = () => {
                // 判断子组件的顶部是否在视图的底部之上
                // 以及子组件的底部是否在视图的顶部之下
                // console.log(scrollTop,offsetHeight,visibleInfo)
                return (
                     scrollTop <= visibleInfo.top+visibleInfo.height+100 &&
                    scrollTop + offsetHeight >= visibleInfo.top-100
                );
                // return 1;
            };
            setIsVisible(isElementInView())
            // 监听滚动事件，以更新isInView状态
            const handleScroll = () => {
                setIsVisible(isElementInView());
            };

            // 添加滚动事件监听器
            root.current.addEventListener('scroll', handleScroll);
            return () => {
                root.current.removeEventListener('scroll', handleScroll);
            };
        }
    }, [src, height, top, visibleInfo]); // 当src变化时重新设置观察者
    return <Paper className={clsx(classes.root, classes[color])}
                  ref={root}
                  onClick={onClick instanceof Function ? onClick : undefined}>
        {/*<div className={classes.topBar}>*/}
        {/*    <div className={classes.source}>*/}
        {/*        <Typography className={classes.sourceText}>{source}</Typography>*/}
        {/*    </div>*/}
        {/*    <div className={classes.triangle}/>*/}
        {/*    <Typography className={classes.title}>*/}
        {/*        {id}*/}
        {/*    </Typography>*/}
        {/*    <div className={classes.btns}>*/}
        {/*        {!!demo &&*/}
        {/*        <a className={classes.btn}*/}
        {/*           style={{backgroundImage: `url(${demoIcon})`}}*/}
        {/*           href={demo}*/}
        {/*        />}*/}
        {/*        {!!paper &&*/}
        {/*        <a className={classes.btn}*/}
        {/*           style={{backgroundImage: `url(${paperIcon})`}}*/}
        {/*           href={paper}*/}
        {/*          // onClick={() => data.download(paper, `${id}.pdf`)}*/}
        {/*        />}*/}
        {/*        {!!video &&*/}
        {/*        <a className={classes.btn}*/}
        {/*           style={{backgroundImage: `url(${videoIcon})`}}*/}
        {/*           href={video}*/}
        {/*        />}*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className={classes.content}>
            <div className={classes.source}>
                <Typography className={classes.sourceText}>{source}</Typography>
                <div className={classes.triangle}/>
            </div>
            <div className={classes.imgPlaceholder}>
            </div>
            <div className={classes.imgContainer}>
                {isVisible?
                <img className={classes.img} alt={teaser} src={src}
                     onLoad={onLoad instanceof Function ? onLoad : undefined}/>:null}
            </div>
            <div className={classes.textInfo}>
                <Typography className={classes.textTitle}>
                    {title}
                    {!!titleKey && <span className={classes.textTitleKey}> [{titleKey.join(', ')}]</span>}
                </Typography>
                <Typography className={classes.textAuthors}>{data.joinAuthors(authors)}</Typography>
                <Typography
                  className={classes.textPublication}>{data.joinPublication(transaction, volume, issue, articleNo, page, year)}</Typography>
            </div>
            <div className={classes.btns}>
                {!!demo &&
                <a className={classes.btn}
                   style={{backgroundImage: `url(${demoIcon})`}}
                   href={demo}
                />}
                {!!paper &&
                <a className={classes.btn}
                   style={{backgroundImage: `url(${paperIcon})`}}
                   href={paper}
                  // onClick={() => data.download(paper, `${id}.pdf`)}
                />}
                {!!video &&
                <a className={classes.btn}
                   style={{backgroundImage: `url(${videoIcon})`}}
                   href={video}
                />}
            </div>
        </div>
    </Paper>
}

export default inject('system', 'data')(observer(Project));
