import React from 'react';
import {Typography, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import gif from './IDG-simplified.gif';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginRight: props => props.isMobile ? 0 : theme.spacing(8),
    },
    title: {
        color: theme.palette.primary.contrastText,
        fontSize: props => props.isMobile ? 18 : 24,
        fontFamily: 'Krungthep'
    },
    logo: {
        margin: props => props.isMobile ? theme.spacing(0, 1) : theme.spacing(0, 2),
    }
}));

function Logo({t, system}) {
    const classes = useStyles({isMobile: system.isMobile});
    const scale = 1 / 6;

    return <div className={classes.root}>
        <img className={classes.logo} style={{
              width: 231.92 * scale,
              height: 231.92 * scale
          }} src={gif} alt={'gif'}/>
        <Typography className={classes.title}>{t('GroupName')}</Typography>
    </div>
}

export default inject('t', 'system')(observer(Logo));
