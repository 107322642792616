export function parseCookie(str) {
    const cookie = {};
    if (typeof str === 'string') {
        str.split(';').forEach(item => {
            const [key, val] = item.split('=')
            if (!isNaN(val)) cookie[key] = parseInt(val);
            else cookie[key] = val;
        })
    }
    return cookie;
}

export function joinCookie(cookie) {
    return Object.keys(cookie).map(key => `${key}=${cookie[key]}`).join(';')
}
