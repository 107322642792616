import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    // link: {
    //     textDecoration: 'underline',
    // },
    uList: {
        margin: 0,
        paddingLeft: 14,
    }
}));

function IntroItem({type, content}) {
    const classes = useStyles();

    switch (type) {
        case "email":
            return <a href={`mailto:${content}`}>{content}</a>
        case "link":
            return <a href={content} target={'_blank'} rel="noopener noreferrer">{content}</a>
        case "ul":
            return <ul className={classes.uList}>
                {content.map((sentence, sId) => <li key={sId}>{sentence}</li>)}
            </ul>
        default:
            return content;
    }
}

export default inject()(observer(IntroItem));
