import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Carousel from "../../CompLib/Carousel";
import CarouselItem from "../../CompLib/CarouselItem";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '56.25%',
        marginBottom: theme.spacing(3),
    },
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '100%',
    }
}));

function TopSlides({data}) {
    const classes = useStyles();
    const {slides} = data;

    return <div className={classes.root}>
        <div className={classes.container}>
            <Carousel autoSlide interval={5000}>
                {slides.map((slide, sId) => <CarouselItem key={sId}
                                                          title={slide.title} subtitle={slide.subtitle}
                                                          imgSrc={slide.imgSrc} link={slide.link}/>)}
            </Carousel>
        </div>
    </div>
}

export default inject('data')(observer(TopSlides));
