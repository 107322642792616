import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Section from "../../CompLib/Section";
import Project from "./Project";
import {withRouter} from "react-router-dom";
import MobProject from "./MobProject";

const useStyles = makeStyles(theme => ({
    placeholder: {
        width: '100%',
        height: theme.spacing(3),
    },
}));

function FeaturedProjects({t, system, data, history: {push}}) {
    const classes = useStyles();

    return <Section title={t('FeaturedProjects')} onMore={() => push('/publications')}>
        {data.featuredProject.map((project, pId) => system.isMobile ?
          <MobProject key={pId} project={project}/> :
          <Project key={pId} project={project}/>)}
        <div className={classes.placeholder}/>
    </Section>
}

export default withRouter(inject('t', 'system', 'data')(observer(FeaturedProjects)));
