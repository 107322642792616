import React from 'react';
import clsx from 'clsx';
import {fade, makeStyles, Paper, Typography, useTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import useWorkPic from "./useWorkPic";
import groupLogo from '../../../assets/zjuidg_grey.svg';
import ColoredSvg from "../../CompLib/ColoredSvg";

// circle version
// const useStyles = makeStyles(theme => ({
//     root: {
//         padding: props => props.isMobile ? theme.spacing(0) : theme.spacing(1),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
//     hasLink: {
//         cursor: 'pointer'
//     },
//     photo: {
//         margin: props => props.isMobile ? theme.spacing(2) : theme.spacing(0),
//         paddingTop: props => props.isMobile ? `calc(100% - ${theme.spacing(4)}px)` : '100%',
//         border: `1.5px solid ${theme.palette.secondary.main}`,
//         borderRadius: '50%',
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//     },
//     name: {
//         fontWeight: 'bolder',
//         fontSize: props => props.isMobile ? 14 : 20,
//         margin: props => props.isMobile ? theme.spacing(-1, 0, 0) : theme.spacing(1, 0),
//         color: theme.palette.getContrastText(theme.palette.background.default),
//     },
//     group: {
//         fontSize: props => props.isMobile ? 14 : 16,
//         color: theme.palette.getContrastText(theme.palette.background.default),
//     }
// }));

// rect version
const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    hasLink: {
        cursor: 'pointer'
    },
    pic: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'white',
    },
    work: {
        position: 'relative',
        width: '100%',
        paddingTop: '55%',
        height: 0,
    },
    groupLogo: {
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translate(-50%, -50%)',
    },
    separateBar: {
        width: '100%',
        height: 4,
        backgroundColor: theme.palette.secondary.main,
        position: 'relative',
    },
    avatar: {
        position: "absolute",
        top: 2,
        left: '10%',
        width: '30%',
        paddingTop: '30%',
        transform: 'translate(0, -50%)',
        border: `1.5px solid ${theme.palette.secondary.main}`,
        borderRadius: '50%',
    },
    year: {
        position: 'absolute',
        top: 6,
        color: theme.palette.secondary.main,
        right: 8,
        textAlign: 'right',
    },
    textArea: {
        paddingTop: '18%',
        paddingBottom: theme.spacing(1),
        paddingLeft: '12%',
    },
    name: {
        fontWeight: 'bolder',
        fontSize: props => props.isMobile ? 14 : 20,
        color: theme.palette.getContrastText(theme.palette.background.default),
        textAlign: 'left',
    },
    group: {
        fontSize: props => props.isMobile ? 14 : 16,
        color: theme.palette.getContrastText(theme.palette.background.default),
        textAlign: 'left',
    }
}));

function Student({system, data, student}) {
    const classes = useStyles({isMobile: system.isMobile});
    const {sortedPublications} = data;
    const {url, name, photo, work, group, title, degree, graduateYear, startYear} = student;

    // circle version
    // return <Paper className={clsx(classes.root, {[classes.hasLink]: !!url})}
    //               elevation={0}
    //               onClick={() => !!url && window.open(url)}>
    //     <div className={classes.photo} style={{backgroundImage: `url(${photo})`}}/>
    //     <Typography className={classes.name}>{name}</Typography>
    //     <Typography className={classes.group}>{group}</Typography>
    //     {title === 'graduated' ?
    //       <Typography className={classes.group}>{degree}{system.isMobile ? <br/> : ', '}{graduateYear}</Typography>:
    //       <Typography className={classes.group}>{startYear}</Typography>}
    // </Paper>

    // rect version
    const workPic = useWorkPic(name, sortedPublications, work);
    const theme = useTheme();
    return <Paper className={clsx(classes.root, {[classes.hasLink]: !!url})}
                  elevation={2}
                  onClick={() => !!url && window.open(url)}>
        {workPic === null ?
          <div className={clsx(classes.pic, classes.work)}>
              <ColoredSvg classes={classes.groupLogo}
                          src={groupLogo} color={fade(theme.palette.secondary.main, 0.3)}
                          width={'50%'} height={'50%'}/>
          </div>:
          <div className={clsx(classes.pic, classes.work)}
               style={{backgroundImage: `url(${workPic})`}}/>}
        <div className={classes.separateBar}>
            <div className={clsx(classes.pic, classes.avatar)} style={{backgroundImage: `url(${photo})`}}/>
            {title === 'graduated' ?
              <Typography className={classes.year}>
                  {degree}
                  <br/>
                  {graduateYear}
              </Typography> :
              <Typography className={classes.year}>{startYear}</Typography>}
        </div>
        <div className={classes.textArea}>
            <Typography className={classes.name}>{name}</Typography>
            <Typography className={classes.group}>{group}</Typography>
        </div>
    </Paper>
}

export default inject('system', 'data')(observer(Student));
