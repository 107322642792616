import React from 'react';
import clsx from 'clsx';
import {Typography, makeStyles} from "@material-ui/core";
import {Link} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import Section from "../../CompLib/Section";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2, 0),
    },
    time: {
        margin: theme.spacing(1, 0),
        color: 'grey',
        fontSize: props => props.isMobile ? 14 : 18,
    },
    summaryContainer: {
        cursor: 'pointer',
        position: 'relative',
        "&:hover > $withLink": {
            textDecoration: 'underline',
        }
    },
    summary: {
        fontSize: props => props.isMobile ? 14 : 18,
        textAlign: 'justify'
    },
    link: {
        position: "absolute",
        left: -theme.spacing(1),
        top: 0,
        fontSize: props => props.isMobile ? 14 : 18,
        transform: 'translateX(-100%) rotate(-45deg)',
    },
    withLink: {},
}));

function Updates({t, system, data, history: {push}}) {
    const classes = useStyles({isMobile: system.isMobile});

    return <Section title={t('News&Updates')}>
        {data.updates.map((update, uId) => {
            const hasLink = !!update.link;
            const hasAttachment = !!update.attachment;

            return <div key={uId} className={classes.root}>
                <Typography className={classes.time}>{update.date}</Typography>
                <div className={classes.summaryContainer}>
                    {(hasLink || hasAttachment) && <Link className={classes.link}/>}
                    <div className={clsx(classes.summary, {
                        [classes.withLink]: hasLink || hasAttachment,
                    })} onClick={() => {
                        if (hasLink) {
                            if (update.link.startsWith("http")) window.open(update.link);
                            else push(update.link);
                        }
                        if (hasAttachment) data.download(`${window.location.origin}/${update.attachment}`, update.attachment.substr(update.attachment.lastIndexOf('/') + 1));
                    }}
                         dangerouslySetInnerHTML={{__html: update.summary}}/>
                </div>
            </div>
        })}
    </Section>
}

export default withRouter(inject('t', 'system', 'data')(observer(Updates)));
