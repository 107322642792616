import {useEffect, useState} from "react";

export const useVisible = (cRef) =>{
    const [top,setTop] = useState(0)
    const [height,setHeight] = useState(0)
    useEffect(()=>{
        if(cRef.current){
            setHeight(cRef.current.offsetHeight)
            setTop(cRef.current.scrollTop)
        }

    },[cRef])
    useEffect(()=>{
        const handleScroll = () => {
            setTop(cRef.current.scrollTop)
            // console.log("top,h",top,height)
        }
        // console.log(cRef.current)
        if (cRef.current){
            cRef.current.addEventListener('scroll',handleScroll)
        }

        return ()=>{
            if(cRef.current){
                cRef.current.removeEventListener('scroll',handleScroll)
            }
        }
    },[height, top])
    // console.log(top,height)
    return [top,height]
}
