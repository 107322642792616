import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import SidePanel from "../CompLib/SidePanel";
import ScrollContent from "../CompLib/ScrollContent";
import ContactUs from "../Home/SidePanel/ContactUs";
import Director from "./SidePanel/Director";
import Students from "./ScrollContent/Students";
import MobMembers from "./MobMembers";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 70,
        left: 0,
        right: 0,
        bottom: 0,
    },
    contentColumn: {
        margin: '0 8%',
        position: 'relative',
    },
}));

function Members({t, system, data}) {
    const classes = useStyles();

    if (system.isMobile) return <MobMembers/>
    return <div className={classes.root}>
        <SidePanel>
            <Director/>
            <ContactUs/>
        </SidePanel>
        <ScrollContent>
            <div className={classes.contentColumn}>
                <Students title={t('PhD')}
                          students={data.students
                            .filter(stu => stu.title === 'phd')
                            .sort((a, b) => {
                                if (a.name === 'Sadia Rubab') return 1;
                                else if (b.name === 'Sadia Rubab') return -1;
                                else return a.startYear - b.startYear
                            })}/>
                <Students title={t('Master')}
                          students={data.students
                            .filter(stu => stu.title === 'master')
                            .sort((a, b) => a.startYear - b.startYear)}/>
                <Students title={t('Undergraduate')}
                          students={data.students
                            .filter(stu => stu.title === 'undergraduate')
                            .sort((a, b) => a.startYear - b.startYear)}/>
                <Students title={t('Graduated')}
                          students={data.students
                            .filter(stu => stu.title === 'graduated')
                            .sort((a, b) => a.graduateYear - b.graduateYear)}/>
            </div>
        </ScrollContent>
    </div>
}

export default inject('t', 'system', 'data')(observer(Members));
