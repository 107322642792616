import React from 'react';
import {makeStyles, IconButton} from "@material-ui/core";
import {Menu} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import Logo from "./Logo";
import MobNavigator from "./MobNavigator";
import {withRouter} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        height: 50,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
    },
    placeholder: {
        flex: 1,
    },
    menuBtn: {
        color: theme.palette.primary.contrastText
    }
}));

function MobAppBar({system, location, history: {push}}) {
    const classes = useStyles();

    return <div className={classes.root}>
        <Logo/>
        <div className={classes.placeholder}/>
        <IconButton className={classes.menuBtn} onClick={() => push(`${location.pathname}?nav=true`)}><Menu/></IconButton>
        <MobNavigator/>
    </div>
}

export default withRouter(inject('system')(observer(MobAppBar)));
