import {action, computed, observable} from "mobx";
import {saveAs} from 'file-saver';

class DataStore {
    constructor() {
        fetch(this.handleURL('source/publications.json'))
          .then(res => res.json())
          .then(res => this.patchPublications(res));
        fetch(this.handleURL('source/slides.json'))
          .then(res => res.json())
          .then(res => this.patchSlides(res));
        fetch(this.handleURL('source/updates.json'))
          .then(res => res.json())
          .then(res => this.patchUpdates(res));
        fetch(this.handleURL('source/featuredProj.json'))
          .then(res => res.json())
          .then(res => this.patchFeaturedProj(res));
        fetch(this.handleURL('source/students.json'))
          .then(res => res.json())
          .then(res => this.patchStudents(res));
        fetch(this.handleURL('source/ycwu.json'))
          .then(res => res.json())
          .then(res => this.patchDirector(res));
    }

    @observable.shallow publications = [];
    @observable.shallow sortedPublications = [];
    @action patchPublications = newPublications => {
        const classifiedPubs = {};
        const sortedPublications = [];
        const yearRange = [3000, 1000];
        this.publications = newPublications.map(pub => {
            pub.teaser = this.handleURL(pub.teaser);
            pub.paper = this.handleURL(pub.paper);

            if (!classifiedPubs.hasOwnProperty(pub.year)) classifiedPubs[pub.year] = [];
            classifiedPubs[pub.year].push(pub);
            yearRange[0] = Math.min(yearRange[0], pub.year);
            yearRange[1] = Math.max(yearRange[1], pub.year);

            return pub;
        });
        for (let i = yearRange[1]; i >= yearRange[0]; i--) {
            if (classifiedPubs.hasOwnProperty(i)) {
                sortedPublications.push({
                    year: i,
                    publications: classifiedPubs[i]
                });
            }
        }


        this.sortedPublications = sortedPublications;
    }
    joinAuthors = authors => {
        if (authors.length === 1) return authors;
        return authors.slice(0, authors.length - 1).join(', ') + ', and ' + authors[authors.length - 1];
    }
    joinPublication = (transaction, volume, issue, articleNo, page, year) => {
        let pub = `${transaction}`;
        // if (!!volume) pub += `, Vol. ${volume}`;
        // if (!!issue) pub += `, Issue ${issue}`;
        // if (!!articleNo) pub += `, Article No. ${articleNo}`;
        // if (!!page) pub += `, Page ${page[0]}-${page[1]}`;
        // pub += `, ${year}.`
        return pub;
    }

    @observable.shallow featuredProj = [];
    @action patchFeaturedProj = newFeatureProj => this.featuredProj = newFeatureProj;

    @computed get featuredProject() {
        const publicationIds = this.publications.map(d => d.id);
        return this.featuredProj.map(proj => {
            const idx = publicationIds.indexOf(proj);
            if (idx === -1) return null;
            const publication = this.publications[idx];

            return JSON.parse(JSON.stringify(publication,
              ['id', 'source', 'title', 'authors', 'teaser', 'transaction', 'volume', 'articleNo', 'issue', 'page', 'year', 'demo', 'paper', 'video']));
        }).filter(d => !!d);
    }

    @observable.shallow slides = [];
    @action patchSlides = newSlides => this.slides = newSlides.map(slide => {
        slide.imgSrc = this.handleURL(slide.imgSrc);
        return slide;
    });

    @observable.shallow updates = [];
    @action patchUpdates = newUpdates => this.updates = newUpdates;

    @observable.shallow students = [];
    @action patchStudents = newStudents => this.students = newStudents.map(stu => {
        stu.photo = this.handleURL(stu.photo);
        return stu;
    });

    @observable.shallow director = {
        photo: '',
        name: '',
        intro: [],
        links: [],
    };
    @action patchDirector = newDirector => this.director = newDirector;

    introVideo = './source/intro.mp4';

    download = (file, name) => {
        if (name.toLowerCase().endsWith('pdf')) window.open(file);
        else
            fetch(file)
              .then(res => {
                  return res.blob()
              })
              .then(res => {
                  saveAs(res, name);
              });
    }

    handleURL = url => {
        return `${window.location.origin}/${url}`;
    }
}

export default DataStore;
