import React from 'react';
import {Typography, makeStyles} from "@material-ui/core";
import {Copyright as CopyrightIcon} from "@material-ui/icons";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '20px',
    },
}));

function Copyright({t, system}) {
    const classes = useStyles();

    return <Typography className={classes.root}>
        Copyright <CopyrightIcon style={{fontSize: 14, transform: 'translateY(3px)'}}/> {t('Copyright')}.
        {system.isMobile && <br/>}
        All Rights Reserved.
    </Typography>
}

export default inject('t', 'system')(observer(Copyright));
