import SystemStore from "./System";
import Language from "./Language";
import DataStore from "./Data";

class Store {
    constructor() {
        console.log('v1.0.4');
    }
    system = new SystemStore();
    lang = new Language();
    data = new DataStore();
}

const store = new Store();
export default store;
