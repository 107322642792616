import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    video: {
        // margin: '0 5%',
        // width: '90%',
        width: '100%',
    }
}));

function BriefVideo({data}) {
    const classes = useStyles();

    return <div className={classes.root}>
        <video className={classes.video} src={data.introVideo} controls/>
    </div>
}

export default inject('data')(observer(BriefVideo));
