import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: props => props.isMobile ? 50 : 20,
        height: props => props.isMobile ? 50 : 20,
        margin: props => props.isMobile ? theme.spacing(1.5) : theme.spacing(2.5),
        '&~&': {
            marginLeft: props => props.isMobile ? theme.spacing(1.5) : 0,
        },
        cursor: 'pointer',
    },
    border: {
        border: `2px solid ${theme.palette.primary.contrastText}`,
        width: '100%',
        height: '100%',
    },
    triangle: {
        position: 'absolute',
        borderLeft: '10px solid transparent',
        borderTop: `10px solid ${theme.palette.primary.contrastText}`,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
    }
}));

function ThemeButton({system, color, idx}) {
    const classes = useStyles({isMobile: system.isMobile});
    const isMatch = idx === system.themeChoice;

    return <div className={clsx(classes.root)}
                style={{
                    backgroundColor: color,
                }}
                onClick={() => system.changeTheme(idx)}>
        {isMatch && <React.Fragment>
            <div className={classes.border}/>
            <div className={classes.triangle}/>
        </React.Fragment>}
    </div>
}

export default inject('system')(observer(ThemeButton));
