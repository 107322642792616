import React from 'react';
import {Typography, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    root: {},
    titleBar: {
        margin: theme.spacing(2, 0, 1.5),
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        position: 'relative',
        textTransform: 'uppercase',
        fontFamily: 'Krungthep',
        fontSize: props => props.isMobile ? 18 : 24,
        flex: '0 0 auto',
    },
    underline: {
        position: 'absolute',
        bottom: -3,
        width: '100%',
        height: 6,
        borderRadius: 3,
        backgroundColor: theme.palette.secondary.main,
    },
    placeholder: {
        flex: 1,
    },
    more: {
        textTransform: 'uppercase',
        fontSize: props => props.isMobile ? 18 : 24,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        flex: '0 0 auto',
    }
}));

function Section({system, title, moreText = null, onMore = null, children, t}) {
    const classes = useStyles({isMobile: system.isMobile});

    return <div className={classes.root}>
        <div className={classes.titleBar}>
            <Typography className={classes.title} component={'div'}>
                {title}
                <div className={classes.underline}/>
            </Typography>
            <div className={classes.placeholder}/>
            {onMore instanceof Function && <Typography className={classes.more}
                                                       onClick={onMore}>{moreText || `${t('More')}>>`}</Typography>}
        </div>
        {children}
    </div>
}

export default inject('t', 'system')(observer(Section));
