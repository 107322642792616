import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import TopSlides from "./ScrollContent/TopSlides";
import AboutIDG from "./SidePanel/AboutIDG";
import BriefVideo from "./SidePanel/BriefVideo";
import Updates from "./ScrollContent/Updates";
import FeaturedProjects from "./ScrollContent/FeaturedProjects";
import ContactUs from "./SidePanel/ContactUs";
import Copyright from "./SidePanel/Copyright";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        overflow: 'auto',
        height: '100vh',
    },
    appBarPlaceholder: {
        height: 30
    },
    section: {
        margin: theme.spacing(3, -1, 3, 0),
    }
}));

function MobHome({t}) {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.appBarPlaceholder}/>
        <div className={classes.section}><TopSlides/></div>
        <div className={classes.section}><AboutIDG needLogo={false}/></div>
        <div className={classes.section}><BriefVideo/></div>
        <div className={classes.section}><Updates/></div>
        <div className={classes.section}><FeaturedProjects/></div>
        <div className={classes.section}><ContactUs/></div>
        <div className={classes.appBarPlaceholder}/>
        <Copyright/>
    </div>
}

export default inject('t')(observer(MobHome));
