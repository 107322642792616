import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {fade, makeStyles} from "@material-ui/core";
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    container: {
        height: '100%',
        display: 'flex',
        transition: 'transform 200ms linear',
    },
    navigateBtn: {
        position: 'absolute',
        width: 80,
        top: 0,
        bottom: 0,
    },
    leftBtn: {
        left: 0,
        "&:hover > $buttonContent": {
            opacity: 1,
        },
        "& > $buttonContent": {
            background: `linear-gradient(to left, transparent, ${theme.palette.background.default})`,
        }
    },
    rightBtn: {
        right: 0,
        "&:hover > $buttonContent": {
            opacity: 1,
        },
        "& > $buttonContent": {
            background: `linear-gradient(to right, transparent, ${theme.palette.background.default})`,
        }
    },
    buttonContent: {
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'opacity 200ms linear',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnIcon: {
        fontSize: 50,
    },
    stepper: {
        position: 'absolute',
        bottom: 0,
        height: '10%',
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    step: {
        cursor: 'pointer',
        margin: theme.spacing(0, 0.25),
        flex: '0 1 40px',
        width: 40,
        height: 4,
        borderRadius: 4,
        backgroundColor: fade(theme.palette.primary.contrastText, 0.5),
    },
    activateStep: {
        backgroundColor: theme.palette.primary.contrastText,
    }
}));

function Carousel({system, classes: overrideClasses, children, autoSlide, interval}) {
    const classes = useStyles({isMobile: system.isMobile});
    const slideCount = children.length;
    const [currentSlide, setSlide] = useState(0);
    const [pause, setPause] = useState(false);
    if (!(interval instanceof Number)) interval = 5000;

    useEffect(() => {
        const iId = setInterval(() => {
            if (!autoSlide) return;
            if (pause) return;
            setSlide((currentSlide + 1) % slideCount);
        }, interval);
        return () => clearInterval(iId);
    }, [slideCount, currentSlide, pause, interval, autoSlide]);

    return <div className={clsx(classes.root, overrideClasses)}
                onMouseEnter={() => setPause(true)} onMouseLeave={() => setPause(false)}>
        <div className={classes.container}
             style={{
                 width: `${slideCount}00%`,
                 transform: `translateX(-${100 / slideCount * currentSlide}%)`
             }}>
            {children}
        </div>
        <div className={clsx(classes.navigateBtn, classes.leftBtn)}
             onClick={() => setSlide((currentSlide + slideCount - 1) % slideCount)}>
            <div className={classes.buttonContent}>
                <NavigateBefore color={"primary"} className={classes.btnIcon}/>
            </div>
        </div>
        <div className={clsx(classes.navigateBtn, classes.rightBtn)}
             onClick={() => setSlide((currentSlide + 1) % slideCount)}>
            <div className={classes.buttonContent}>
                <NavigateNext color={"primary"} className={classes.btnIcon}/>
            </div>
        </div>
        <div className={clsx(classes.stepper)}>
            {[...new Array(slideCount)].map((_, i) => {
                return <div key={i}
                            className={clsx(classes.step, {
                                [classes.activateStep]: i === currentSlide
                            })}
                            onMouseEnter={() => setSlide(i)}/>
            })}
        </div>
    </div>
}

export default inject('system')(observer(Carousel));
