import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {withRouter} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.isMobile ? '100%' : 'auto',
        height: props => props.isMobile ? 'fit-content' : '100%',
        flex: '0 1 auto',
        padding: `6px ${theme.spacing(5.5)}px`,
        cursor: 'pointer',
        textTransform: 'none',
        borderRadius: 0,
        border: 'none',
        borderBottom: props => props.isMobile ? `1px solid ${theme.palette.primary.light}` : 'none',
        boxShadow: 'none',
        fontSize: props => props.isMobile ? 18:21,
        '&:hover': {
            boxShadow: 'none',
        }
    },
}));

function SwitchButton({path, label, exact, location, history: {push}, system}) {
    const classes = useStyles({isMobile: system.isMobile});
    const isMatch = exact ? location.pathname === path : location.pathname.startsWith(path);

    return <Button className={classes.root}
                   variant={'contained'} color={isMatch ? 'secondary' : 'primary'}
                   onClick={() => push(path)}>
        {label}
    </Button>
}

export default withRouter(inject('system')(observer(SwitchButton)));
