import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        overflowY: 'auto',
        left: `calc(35vw + ${theme.spacing(3)}px)`,
        right: theme.spacing(3),
        top: 0,
        bottom: 0,
        '&::-webkit-scrollbar-button': {
            display: 'block',
            height: theme.spacing(1.5),
        },
    },
    padding: {
        height: theme.spacing(3),
        width: '100%',
    },
    content: {
        width: '100%',
        height: 'fit-content',
        overflow: 'hidden',
    },
}));

function ScrollContent({children}) {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.padding}/>
        <div className={classes.content}>
            {children}
        </div>
        <div className={classes.padding}/>
    </div>
}

export default inject()(observer(ScrollContent));
