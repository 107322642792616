import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import AppBar from "./AppBar/AppBar";
import {BrowserRouter as Router, Route, Switch, useLocation, Redirect} from "react-router-dom";

function RouteRedirect({isValidRoute}) {
    const location = useLocation();
    if (isValidRoute(location)) return null;
    return <Redirect to="/"/>
}

function App({system}) {
    useEffect(() => {
        const logoContainer = document.getElementById('logoContainer');
        const root = document.getElementById('root');
        logoContainer.style.opacity = '0';
        root.style.opacity = '1';
    }, []);

    const isValidRoute = (loc) => {
        for (const routeOption of system.routes) 
            if (loc.pathname.startsWith(routeOption.path))
                if (routeOption.isExact) return loc.pathname === routeOption.path;
                else return loc.pathname === routeOption.path || loc.pathname.startsWith(routeOption.path + "/");
        return false;
    }

    return <MuiThemeProvider theme={system.theme}>
        <CssBaseline/>
        <Router>
            <AppBar/>
            <Switch>
                {system.routes.map(routeOption => {
                    return <Route key={routeOption.path} path={routeOption.path} exact={routeOption.isExact}>
                        <routeOption.page/>
                    </Route>
                })}
                <RouteRedirect isValidRoute={isValidRoute}/>
            </Switch>
        </Router>
    </MuiThemeProvider>;
}

export default inject('system')(observer(App));
