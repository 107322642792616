import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Section from "../CompLib/Section";

const useStyles = makeStyles(theme => ({
    videoArea: {
        width: '100%',
        paddingTop: '56%',
        height: 0,
        position: 'relative'
    },
    abstract: {
        fontSize: 18,
        textAlign: 'justify',
    },
    video: {
        border: 'none',
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
    }
}));

function Publication({publication, t, p, data}) {
    const classes = useStyles();

    return <React.Fragment>
        {!!publication.embedVideo && <Section title={t('Demo')}
                                              moreText={t('DemoSystem')}
                                              onMore={publication.system ? () => window.open(publication.system) : undefined}>
            <div className={classes.videoArea}>
                <iframe className={classes.video}
                        title={publication.embedVideo}
                        allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
                        allowFullScreen
                        src={publication.embedVideo}/>
            </div>
        </Section>}
        <Section title={t('Abstract')}
                 moreText={t('FullPaper')}
                 onMore={publication.paper ? () => data.download(publication.paper, `${publication.id}.pdf`) : undefined}>
            <div className={classes.abstract}
                 dangerouslySetInnerHTML={{__html: p(publication.abstract)}}/>
        </Section>
    </React.Fragment>
}

export default inject('t', 'p', 'data')(observer(Publication));
