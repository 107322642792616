import React, {useRef} from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Section from "../CompLib/Section";
import MobProject from "../Home/ScrollContent/MobProject";
import {withRouter} from "react-router-dom";
import List from "../CompLib/List";
import {useVisible} from './UseVisible'
const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 70,
        left: 0,
        right: 0,
        bottom: 0,
    },
}));

function MobPublications({t, data, location}) {
    const classes = useStyles();
    const {sortedPublications: sortedPubs} = data;
    let count = 0, currentPub = undefined;
    const contentRef = useRef(null)
    const [top,height] = useVisible(contentRef)
    const list = sortedPubs.reduce((p, year, yId) => {
        count++;
        return p.concat([
            <Section key={`y${yId}`} title={year.year}/>,
            ...year.publications
              .map(pub => {
                  if (location.pathname.endsWith(pub.id)) currentPub = count;
                  count++;
                  return measure => <MobProject key={pub.id}
                                                visibleInfo={{"top":top,"height":height}}
                                                project={pub}
                                                onLoad={measure}
                                                color={'secondary'}/>
              })
        ])
    }, []);

    return <div className={classes.root}>
        <List defaultIndex={currentPub} ref={contentRef}>
            {list}
        </List>
    </div>
}

export default withRouter(inject('t', 'data')(observer(MobPublications)));
