import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import SidePanel from "../CompLib/SidePanel";
import ScrollContent from "../CompLib/ScrollContent";
import {withRouter} from "react-router-dom";
import Project from "../Home/ScrollContent/Project";
import Publication from "./Publication";
import Section from "../CompLib/Section";
import MobPublications from "./MobPublications";
import List from "../CompLib/List";
import {useRef} from "react";
import {useVisible} from './UseVisible'
const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 70,
        left: 0,
        right: 0,
        bottom: 0,
    },
    contentColumn: {
        height: `calc(100vh - 70px - ${theme.spacing(6)}px)`,
        margin: `0 0 0 4%`,
        position: 'relative',
        overflowX: 'visible'
    }
}));

function Publications({system, data, location, history: {push}}) {
    const classes = useStyles();
    const {sortedPublications: sortedPubs} = data;
    const contentRef = useRef(null)

    const [top,height] = useVisible(contentRef)
    let count = 0, currentIdx = undefined, currentPub = [0, 0];
    const list = sortedPubs.reduce((p, year, yId) => {
        count++;
        return p.concat([
            <Section key={`y${yId}`} title={year.year}/>,
            ...year.publications
              .map((pub, pId) => {
                  let color = 'primary';
                  if (location.pathname.endsWith(pub.id)) {
                      currentIdx = count;
                      currentPub = [yId, pId];
                      color = 'secondary';
                  }
                  count++;
                  return measure => <Project key={pub.id}
                                             visibleInfo={{"top":top,"height":height}}
                                             project={pub}
                                             color={color}
                                             onLoad={measure}
                                             onClick={() => push(`/publications/${pub.id}`)}/>
              })
        ])
    }, []);

    if (system.isMobile) return <MobPublications/>;
    return <div className={classes.root}>
        <SidePanel>
            {currentPub[0] < sortedPubs.length &&
            currentPub[1] < sortedPubs[currentPub[0]].publications.length &&
            <Publication publication={sortedPubs[currentPub[0]].publications[currentPub[1]]}/>}
        </SidePanel>
        <ScrollContent >
            <div className={classes.contentColumn}>
                <List defaultIndex={currentIdx} ref={contentRef}>
                    {list}
                </List>
            </div>
        </ScrollContent>
    </div>
}

export default withRouter(inject('system', 'data')(observer(Publications)));
