import React from 'react';
import {Typography, makeStyles} from "@material-ui/core";
import {Email, LocationOn} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import Section from "../../CompLib/Section";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        margin: theme.spacing(1, 0),
    },
    texts: {
        marginLeft: theme.spacing(1),
    },
    text: {
        fontSize: props => props.isMobile ? 14 : 18,
    }
}));

function ContactItem({system, icon, text, type}) {
    const classes = useStyles({isMobile: system.isMobile});
    const texts = text instanceof Array ? text : [text];

    switch (type) {
        case 'email':
            return <div className={classes.root}>
                {icon}
                <div className={classes.texts}>
                    {texts.map((text, tId) => <a href={`mailto:${text}`} key={tId} className={classes.text}>{text}</a>)}
                </div>
            </div>
        default:
            return <div className={classes.root}>
                {icon}
                <div className={classes.texts}>
                    {texts.map((text, tId) => <Typography key={tId} className={classes.text}>{text}</Typography>)}
                </div>
            </div>
    }
}
const ContactItemWrapper = inject('system')(observer(ContactItem));

function ContactUs({t}) {
    return<Section title={t('ContactUs')}>
        <ContactItemWrapper icon={<Email/>} text={t('Mail')} type={'email'}/>
        <ContactItemWrapper icon={<LocationOn/>} text={[t('Institute'), t('Location')]}/>
    </Section>
}

export default inject('t')(observer(ContactUs));
