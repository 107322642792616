import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import store from "./store";
import {Provider} from "mobx-react";

ReactDOM.render(
  <React.StrictMode>
      <Provider system={store.system} lang={store.lang} t={store.lang.t} p={store.lang.p} data={store.data}>
          <App/>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
