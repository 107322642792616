import React from 'react';
import clsx from 'clsx';
import {Typography, fade, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        flex: '1 1 0',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    hasLink: {
        cursor: 'pointer',
    },
    infoPanel: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: props => props.isMobile ? 60 : '25%',
        backgroundColor: fade(theme.palette.primary.main, 0.5)
    },
    title: {
        width: '100%',
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontSize: props => props.isMobile ? 14 : 24,
    },
    subtitle: {
        width: '100%',
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontSize: props => props.isMobile ? 14 : 18,
    },
}));

export function CarouselItem({system, title, subtitle, imgSrc, link, history: {push}}) {
    const classes = useStyles({isMobile: system.isMobile});

    return <div className={clsx(classes.root, {
        [classes.hasLink]: !!link
    })}
                style={{backgroundImage: `url(${imgSrc})`}}
                onClick={() => !!link && (link.startsWith("http") ? window.open(link) : push(link))}>
        <div className={classes.infoPanel}>
            <Typography className={classes.title}>{title}</Typography>
            {!system.isMobile && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </div>
    </div>
}

export default withRouter(inject('system')(observer(CarouselItem)));
