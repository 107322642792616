import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    list: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        marginRight: props => props.isMobile ? theme.spacing(0) : theme.spacing(-3),
        paddingRight: props => props.isMobile ? theme.spacing(0) : theme.spacing(3),
        paddingLeft: theme.spacing(1),
    },
    root: {
        paddingRight: props => theme.spacing(props.isMobile ? 1 : 3),
        transform: props => `translateX(${theme.spacing(props.isMobile ? 0.5 : 1.5)}px)`,
        outline: 'none',
        width: props => `calc(100% - ${theme.spacing(props.isMobile ? 1 : 3)}px)`,
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

const scrollTo = (ele, idx) => {
    const child = ele.children[idx];
    if (!child) return;
    ele.scrollTo({
        top: child.offsetTop,
        behavior: 'smooth',
    })
}

const useDefaultIdx = (cRef, defaultIdx) => {
    const [autoScroll, setAutoScroll] = useState(true);
    const disableScroll = () => setAutoScroll(false);
    const reScroll = useCallback(
      () => autoScroll && scrollTo(cRef.current, defaultIdx),
      [autoScroll, defaultIdx]
    );
    useEffect(() => {
        reScroll();
    }, [defaultIdx, reScroll]);

    return [reScroll, disableScroll];
}

const List = forwardRef(({system , children, defaultIndex}, ref) => {
    const classes = useStyles({isMobile: system.isMobile});

    const [reScroll, disableScroll] = useDefaultIdx(ref, defaultIndex);

    return <div className={classes.list}>
        <div ref={ref} className={classes.root} onScroll={disableScroll}>
            {children.map((c, i) => {
                return (c instanceof Function) ? c(() => {
                    if (i <= defaultIndex) reScroll();
                }) : c;
            })}
        </div>
    </div>
})

export default inject('system')(observer(List));
