import React from 'react';
import {Paper, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Copyright from "../Home/SidePanel/Copyright";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: theme.spacing(3),
        bottom: theme.spacing(3),
        width: '35vw',
        padding: theme.spacing(1, 5),

        display: 'flex',
        flexDirection: 'column',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    content: {
        flex: 1,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),

        overflowY: "scroll",
        marginRight: -theme.spacing(4),
        paddingRight: theme.spacing(4),
    }
}));

function SidePanel({children}) {
    const classes = useStyles();

    return <Paper className={classes.root} elevation={4}>
        <div className={classes.content}>
            {children}
        </div>
        <Copyright/>
    </Paper>
}

export default inject()(observer(SidePanel));
