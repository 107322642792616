import {useCallback, useEffect, useState} from "react";

export default function useLazyLoader(src, ref) {
    const [loadSrc, setLoadSrc] = useState(undefined);
    const load = useCallback(() => setLoadSrc(src), [src]);
    useEffect(() => {
        // 突然写不来了。。。
        load();
    }, [load]);
    return loadSrc;
}
