import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Director from "./SidePanel/Director";
import Students from "./ScrollContent/Students";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        overflow: 'auto',
        height: '100vh',
    },
    appBarPlaceholder: {
        height: 30
    },
}));

function MobMembers({t, data}) {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.appBarPlaceholder}/>
        <Director/>
        <Students title={t('PhD')}
                  students={data.students
                    .filter(stu => stu.title === 'phd')
                    .sort((a, b) => a.startYear - b.startYear)}/>
        <Students title={t('Master')}
                  students={data.students
                    .filter(stu => stu.title === 'master')
                    .sort((a, b) => a.startYear - b.startYear)}/>
        <Students title={t('Undergraduate')}
                  students={data.students
                    .filter(stu => stu.title === 'undergraduate')
                    .sort((a, b) => a.startYear - b.startYear)}/>
        <Students title={t('Graduated')}
                  students={data.students
                    .filter(stu => stu.title === 'graduated')
                    .sort((a, b) => a.graduateYear - b.graduateYear)}/>
    </div>
}

export default inject('t', 'data')(observer(MobMembers));
