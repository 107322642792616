import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Section from "../../CompLib/Section";
import ColoredSvg from "../../CompLib/ColoredSvg";
import logo from '../../../assets/zjuidg_grey.svg';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    text: {
        flex: 1,
        fontSize: props => props.isMobile ? 14 : 18,
        textAlign: "justify",
    },
    logo: {
        margin: theme.spacing(0, 0, 0, 2),
        flex: '0 0 6vw',
    }
}));

function AboutIDG({t, p, system, needLogo = true}) {
    const classes = useStyles({isMobile: system.isMobile});
    const theme = useTheme();

    return <Section title={t('AboutIDG')}>
        <div className={classes.root}>
            <div className={classes.text}
                 dangerouslySetInnerHTML={{__html: p(t('IntroIDG'))}}/>
            {needLogo && <div className={classes.logo}>
                <ColoredSvg src={logo} color={theme.palette.secondary.main} width={'6vw'} height={'6vw'}/>
            </div>}
        </div>
    </Section>
}

export default inject('t', 'p', 'system')(observer(AboutIDG));
