import React from 'react';
import {makeStyles, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Section from "../../CompLib/Section";
import IntroItem from "./IntroItem";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    avatar: {
        width: props => props.isMobile ? '25vw' : '10vw',
        height: props => props.isMobile ? '25vw' : '10vw',
        borderRadius: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: `1.5px solid ${theme.palette.secondary.main}`,
    },
    name: {
        fontSize: props => props.isMobile ? 18 : 23,
        fontWeight: 'bold',
        margin: theme.spacing(1, 0)
    },
    links: {
        display: 'flex',
        justifyContent: 'center',
    },
    link: {
        margin: theme.spacing(0, 1),
    },
    linkImage: {
        width: 24,
        height: 24,
    },
    table: {
        width: '100%',
        fontSize: props => props.isMobile ? 14 : 18,
        "& td": {
            border: '3px solid transparent',
        }
    },
    title: {
        textAlign: 'right',
        fontWeight: 'bold',
        verticalAlign: 'top',
        whiteSpace: props => props.isMobile ? 'auto' : 'nowrap',
    },
    separator: {
        verticalAlign: 'top',
        whiteSpace: props => props.isMobile ? 'auto' : 'nowrap',
    },
    content: {}
}));

function Director({t, system, data}) {
    const classes = useStyles({isMobile: system.isMobile});
    const {photo, name, intro, links} = data.director;

    return <Section title={t('Director')}>
        <div className={classes.root}>
            <div className={classes.avatar} style={{backgroundImage: `url(${photo})`}}/>
            <Typography className={classes.name}>{name}</Typography>
            <div className={classes.links}>
                {links.map(({name, icon, url}) => {
                    return <a key={name} title={name} href={url} className={classes.link}>
                        <img src={icon} alt={icon} className={classes.linkImage}/>
                    </a>
                })}
            </div>
            <table className={classes.table}>
                <tbody>
                {intro.map((introItem, iId) => <tr key={iId}>
                    <td className={classes.title}>{introItem.key}</td>
                    <td className={classes.separator}>{introItem.separator || ":"}</td>
                    <td className={classes.content}>{<IntroItem type={introItem.type}
                                                                content={introItem.content}/>}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </Section>
}

export default inject('t', 'system', 'data')(observer(Director));
