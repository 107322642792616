import {useMemo} from "react";

export default function useWorkPic(name, sortedPublications, defaultWork) {
    return useMemo(() => {
        if (!!defaultWork) return defaultWork;

        for (const {year, publications} of sortedPublications)
            for (const publication of publications) {
                const {teaser, authors} = publication;
                if (authors[0] === name || (authors[0] === 'Yingcai Wu' && authors[1] === name)) return teaser;
            }

        return null;
    }, [name])
}
