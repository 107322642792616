import React from 'react';
import {Divider, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import SidePanel from "../CompLib/SidePanel";
import ScrollContent from "../CompLib/ScrollContent";
import AboutIDG from "./SidePanel/AboutIDG";
import BriefVideo from "./SidePanel/BriefVideo";
import ContactUs from "./SidePanel/ContactUs";
import TopSlides from "./ScrollContent/TopSlides";
import Updates from "./ScrollContent/Updates";
import FeaturedProjects from "./ScrollContent/FeaturedProjects";
import MobHome from "./MobHome";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 70,
        left: 0,
        right: 0,
        bottom: 0,
    },
    contentColumn: {
        margin: '0 8%',
        position: 'relative',
    },
    divider: {
        margin: theme.spacing(0, 6),
    }
}));

function Home({system}) {
    const classes = useStyles();

    if (system.isMobile) return <MobHome/>
    return <div className={classes.root}>
        <SidePanel>
            <AboutIDG/>
            <BriefVideo/>
            <ContactUs/>
        </SidePanel>
        <ScrollContent>
            <div className={classes.contentColumn}><TopSlides/></div>
            <Divider className={classes.divider}/>
            <div className={classes.contentColumn}><Updates/></div>
            <Divider className={classes.divider}/>
            <div className={classes.contentColumn}><FeaturedProjects/></div>
        </ScrollContent>
    </div>
}

export default inject('system')(observer(Home));
