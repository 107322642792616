import React, {useEffect, useRef, useState} from 'react';
import {Button, makeStyles, Paper, Typography} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import demoIcon from './demo.svg';
import paperIcon from './pdf.svg';
import videoIcon from './video.svg';
import useLazyLoader from "../../../utils/hooks/lazyLoader";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: 100,
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0.5, 1, 1),
        height: 'fit-content',
        backgroundColor: theme.palette.secondary.main,
        textAlign: 'center',
    },
    source: {
        color: theme.palette.secondary.contrastText,
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    content: {
        minHeight: 100,
        margin: theme.spacing(1, 0, 0),
        height: 'fit-content',
        padding: theme.spacing(0, 1)
    },
    id: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    img: {
        width: '100%',
        objectFit: 'contain',
        flex: '0 0 auto',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    authors: {
        fontSize: 14,
        textAlign: 'left'
    },
    publication: {
        fontSize: 14,
        fontStyle: "italic",
        textAlign: 'left'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: theme.spacing(1, 0)
    },
    btn: {
        margin: theme.spacing(0, 1, 0, 0),
        width: 30,
        height: 30,
        cursor: "pointer",
        fontWeight: 'bold',
        textTransform: 'none'
    },
    abstract: {
        fontSize: 14,
        textAlign: 'justify',
    }
}));

function MobProject({visibleInfo, project, p, data, onClick = null, onLoad = null}) {
    const root = useRef();
    const classes = useStyles();
    const [showAbstract, setAbstract] = useState(false);
    const {id, source, title, authors, teaser, transaction, volume, issue, articleNo, page, year, demo, paper, video, abstract} = project;
    const src = useLazyLoader(teaser, root);
    const [isVisible, setIsVisible] = useState(true); // 初始时图片不可见
    // const cRef = useRef(null)
    const height = visibleInfo?.height
    const top = visibleInfo?.top
    useEffect(() => {
        if (root.current && height && top){
            const offsetHeight = root.current.offsetHeight
            const scrollTop = root.current.offsetTop
            // for (const key in root.current)
            //     if (key.toLowerCase().includes('top') || key.includes('Y'))
            //         console.log(key, root.current[key]);
            const isElementInView = () => {
                // 判断子组件的顶部是否在视图的底部之上
                // 以及子组件的底部是否在视图的顶部之下
                // console.log(scrollTop,offsetHeight,visibleInfo)
                return (
                    scrollTop <= visibleInfo.top+visibleInfo.height+100 &&
                    scrollTop + offsetHeight >= visibleInfo.top-100
                );
                // return 1;
            };
            setIsVisible(isElementInView())
            // 监听滚动事件，以更新isInView状态
            const handleScroll = () => {
                setIsVisible(isElementInView());
            };

            // 添加滚动事件监听器
            root.current.addEventListener('scroll', handleScroll);
            return () => {
                root.current.removeEventListener('scroll', handleScroll);
            };
        }
    }, [src, height, top, visibleInfo]); // 当src变化时重新设置观察者
    useEffect(() => {
        if (onLoad instanceof Function) onLoad();
    }, [onLoad, showAbstract]);

    return <Paper className={classes.root}
                  ref={root}
                  onClick={onClick instanceof Function ? onClick : undefined}>
        <Typography className={classes.source}>{source}</Typography>
        <Paper className={classes.content}>
            <Typography className={classes.id}>{id}</Typography>
            {isVisible?
            <img className={classes.img} alt={teaser} src={src}
                 onLoad={onLoad instanceof Function ? onLoad : undefined}/>:null}
            <Typography className={classes.title}
                        dangerouslySetInnerHTML={{__html: title}}/>
            <Typography className={classes.authors}>{data.joinAuthors(authors)}</Typography>
            <Typography
              className={classes.publication}>{data.joinPublication(transaction, volume, issue, articleNo, page, year)}</Typography>
            {!!abstract && <Button className={classes.abstractBtn}
                                   endIcon={showAbstract ? <ExpandLess/> : <ExpandMore/>}
                                   onClick={() => setAbstract(!showAbstract)}>
                Abstract
            </Button>}
            {showAbstract && <div className={classes.abstract}
                                  dangerouslySetInnerHTML={{__html: p(abstract)}}/>}
            <div className={classes.actions}>
                {!!demo &&
                <a className={classes.btn}
                   style={{backgroundImage: `url(${demoIcon})`}}
                   href={demo}
                />}
                {!!paper &&
                <a className={classes.btn}
                   style={{backgroundImage: `url(${paperIcon})`}}
                   href={paper}
                  // onClick={() => data.download(paper, `${id}.pdf`)}
                />}
                {!!video &&
                <a className={classes.btn}
                   style={{backgroundImage: `url(${videoIcon})`}}
                   href={video}
                />}
            </div>
        </Paper>
    </Paper>
}

export default inject('system', 'p', 'data')(observer(MobProject));
