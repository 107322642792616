import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Section from "../../CompLib/Section";
import Student from "./Student";

const useStyles = makeStyles(theme => ({
    spacing: {
        height: theme.spacing(3),
        width: '100%',
    },
}));

function Students({system, title, students}) {
    const classes = useStyles();
    const rowCount = system.isMobile ?
      1 : (system.width >= 1600 ? 3 : 2);

    if (students.length === 0) return <div/>;

    return <React.Fragment>
        <Section title={title}>
            <Grid container spacing={2}>
                {[...new Array(Math.ceil(students.length / rowCount))].map((row, rId) => {
                    return <Grid key={rId} container item spacing={system.isMobile ? 0 : 5}>
                        {students
                          .slice(rId * rowCount, rId * rowCount + rowCount)
                          .map((student, sId) => (
                            <Grid key={sId}
                                  item
                                  xs={12 / rowCount}>
                                <Student key={sId} student={student}/>
                            </Grid>
                          ))}
                    </Grid>
                })}
            </Grid>
        </Section>
        <div className={classes.spacing}/>
    </React.Fragment>
}

export default inject('system')(observer(Students));
