import React from 'react';
import {makeStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Logo from "./Logo";
import SwitchButton from "./SwitchButton";
import ThemeButton from "./ThemeButton";
import MobAppBar from "./MobAppBar";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        height: 65,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
    },
    placeholder: {
        flex: 1,
    }
}));

function AppBar({system}) {
    const classes = useStyles();

    if (system.isMobile) return <MobAppBar/>
    return <div className={classes.root}>
        <Logo/>
        {system.routes.map(routeOption => <SwitchButton key={routeOption.path} exact={routeOption.isExact} path={routeOption.path} label={routeOption.label}/>)}
        <div className={classes.placeholder}/>
        {system.optionalColors.map((optionalColor, cId) => <ThemeButton key={optionalColor} color={optionalColor} idx={cId}/>)}
    </div>
}

export default inject('system')(observer(AppBar));
