import React from 'react';
import {makeStyles, Drawer, Button} from "@material-ui/core";
import {ArrowBack} from '@material-ui/icons';
import {inject, observer} from "mobx-react";
import {useLocation, withRouter} from 'react-router-dom';
import SwitchButton from "./SwitchButton";
import ThemeButton from "./ThemeButton";

const useStyles = makeStyles(theme => ({
    root: {
        width: 200,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden'
    },
    placeholder: {
        flex: 1,
    },
    backArrow: {
        width: '100%',
        height: 50,
        color: theme.palette.primary.contrastText,
        transform: 'rotate(180deg)'
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function MobNavigator({t, system, location, history: {push}}) {
    const classes = useStyles();
    const query = useQuery();

    const closeNav = () => push(location.pathname);

    return <Drawer anchor={"right"} open={query.get('nav') === 'true'} onClose={closeNav}>
        <div className={classes.root}>
            <Button className={classes.backArrow} onClick={closeNav}><ArrowBack/></Button>
            {system.routes.map(routeOption => (
              <SwitchButton key={routeOption.path}
                            exact={routeOption.isExact}
                            path={routeOption.path}
                            label={routeOption.label}/>
            ))}
            <div className={classes.placeholder}/>
            {system.optionalColors.map((optionalColor, cId) => (
              <ThemeButton key={optionalColor}
                           color={optionalColor}
                           idx={cId}/>
            ))}
        </div>
    </Drawer>
}

export default withRouter(inject('t', 'system')(observer(MobNavigator)));
